<template>
    <el-main>
        物资判断
    </el-main>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less">

</style>